import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AppService {
  isResumePage: boolean = true;

  constructor() {}
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../service/app.service";

@Component({
  selector: "radnerus-resume",
  templateUrl: "./resume.component.html",
  styleUrls: ["./resume.component.css"],
})
export class ResumeComponent implements OnInit {
  constructor(private _router: Router, private _service: AppService) {}

  ngOnInit() {
    this._service.isResumePage = this._router.url === "resume";
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { ProfileInfoComponent } from "./profile-info/profile-info.component";
import { ResumeComponent } from "./resume/resume.component";

const routes: Routes = [
  { path: "", component: ProfileInfoComponent },
  { path: "blog", loadChildren: "./blog/blog.module#BlogModule" },
  { path: "resume", component: ResumeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

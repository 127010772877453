import { Component, NgZone } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppService } from "./service/app.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  availableVoices = window.speechSynthesis.getVoices();
  utter: SpeechSynthesisUtterance;
  isResume: boolean;
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _zone: NgZone,
    public _service: AppService
  ) {
    this.utter = new SpeechSynthesisUtterance();
    this.utter.rate = 1;
    this.utter.pitch = 0.5;
  }

  ngOnInit() {}

  listen() {
    const _window: any = window;
    const SpeechRecognition =
      _window.SpeechRecognition || _window.webkitSpeechRecognition;
    const speechRecognition = new SpeechRecognition();

    speechRecognition.onresult = (event) => {
      const current = event.resultIndex;
      const transcript: string = event.results[current][0].transcript;
      console.log(transcript);
      if (transcript.toLowerCase() === "navigate to blog") {
        this._zone.run(() =>
          this._router.navigate(["/blog"], { relativeTo: this._route })
        );
      } else {
        const randomVoice = this.availableVoices[
          Math.floor(Math.random() * (this.availableVoices.length - 1))
        ];
        this.utter.text = transcript;
        this.utter.voice = randomVoice;
        window.speechSynthesis.speak(this.utter);
      }
    };

    speechRecognition.onstart = () => {
      console.log("Voice recognition is ON.");
    };

    speechRecognition.onspeechend = function () {
      console.log("No activity.");
    };

    speechRecognition.onerror = (event) => {
      if (event.error == "no-speech") {
        console.log("Try again.");
      }
    };

    speechRecognition.start();
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'radnerus-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.css']
})
export class ProfileInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
